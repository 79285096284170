import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  // isXSmall = false;
  // isSmall = false;

  breakpoint$ = this.breakpointObserver.observe([
    Breakpoints.Handset,
    Breakpoints.Tablet,
    Breakpoints.Web,
    Breakpoints.XSmall,
    Breakpoints.Small,
  ]).pipe(
    map(data => {
      const deviceClass = {
        // These breakpoints are no longer in sync with Tailwind breakpoints
        'is-mobile': this.breakpointObserver.isMatched(Breakpoints.Handset),
        'is-mobile-landscape': this.breakpointObserver.isMatched(Breakpoints.HandsetLandscape),
        'is-mobile-portrait': this.breakpointObserver.isMatched(Breakpoints.HandsetPortrait),
        'is-tablet': this.breakpointObserver.isMatched(Breakpoints.Tablet),
        'is-tablet-portrait': this.breakpointObserver.isMatched(Breakpoints.TabletPortrait),
        'is-tablet-landscape': this.breakpointObserver.isMatched(Breakpoints.TabletLandscape),
        'is-desktop': this.breakpointObserver.isMatched(Breakpoints.Web),
        'is-small': this.breakpointObserver.isMatched(Breakpoints.Small),
        'is-xSmall': this.breakpointObserver.isMatched(Breakpoints.XSmall),
      }
      const bodyTag = document.body;
      if (this.breakpointObserver.isMatched(Breakpoints.Small) || this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
        bodyTag.classList.add('is-responsive');
      } else {
        bodyTag.classList.remove('is-responsive');
      }
      return deviceClass;
    })
  );

  isResponsive$ = this.breakpointObserver.observe([
    Breakpoints.XSmall,
    Breakpoints.Small,
  ]).pipe(
    map(data => {
      // return object so that we can do async ngif in templates to observe this value. If we would
      // return a boolean value, it affects the ngIf behaviour (true/false), whilst object is always true.
      return { responsive: data.matches };
    }),

  );
  /**
   * everything under 600px is considered mobile
   * https://github.com/angular/components/blob/main/src/cdk/layout/breakpoints.ts
   */
  isMobile$ = this.breakpointObserver.observe([
    Breakpoints.XSmall,
  ]).pipe(
    map(data => {
      // return object so that we can do async ngif in templates to observe this value. If we would
      // return a boolean value, it affects the ngIf behaviour (true/false), whilst object is always true.
      return { mobile: data.matches };
    }),

  );

  /**
   * Navbar title state storages. Using the subject, we can push a new title from different locations into the mobile nav bar
   */
  private _mobileNavBarTitle = new BehaviorSubject<string>('');
  mobileNavBarTitle$ = this._mobileNavBarTitle.asObservable();

  set mobileNavBarTitle(title: string) {
    this._mobileNavBarTitle.next(title);
  }
  get mobileNavBarTitle() {
    return this._mobileNavBarTitle.value;
  }

  constructor(
    public breakpointObserver: BreakpointObserver,
  ) {

  }
}
